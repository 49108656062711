import React, { Component } from 'react';


export default class Footer extends Component {

    public render() {
        return (
            <footer className="site-footer">
                <div className="container">
                    <div className="row">
                        <div >
                            <p className="copyright-text">Copyright &copy; 2022 Pack4pak</p>
                        </div>
                        <div >
                            <ul className="social-icons">

                            </ul>
                        </div>
                    </div>
                </div>
            </footer>
        )
    }

}
