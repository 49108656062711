import React, { Component } from 'react';
import { FaBriefcase, FaBed, FaShuttleVan } from 'react-icons/fa';
import { IoIosRestaurant, IoIosPerson } from "react-icons/io";
import { GrUserPolice } from "react-icons/gr";
import Title from './Title';

interface Service {
    icon: JSX.Element | string;
    title: string;
    info: string;
}

interface IStateServices {
    services: Service[];
}

export default class Services extends Component<{}, IStateServices> {

    public readonly state: Readonly<IStateServices> = {
        services: [
            {
                icon: < FaBriefcase/>,
                title: "Customized Tours",
                info: ""
            },
            {
                icon: < FaBed/>,
                title: "Accomodation",
                info: " "
            },
            {
                icon: <FaShuttleVan />,
                title: "Transportation",
                info: " "
            },
            {
                icon: < IoIosRestaurant />,
                title: "Meal Options",
                info: ""
            }
        ]
    }

    public render() {
        const { services } = this.state;
        return (
            <section className="services">
                <Title title="services" />
                <div className="services-center">
                    {services.map((item: Service, index: number) => {
                        return (
                            <article key={index} className="service">
                                <span>{item.icon}</span>
                                <h6>{item.title}</h6>
                                <p>{item.info}</p>
                            </article>
                        )
                    })}
                </div>
            </section>
        )
    }
}
