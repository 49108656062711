import room1 from "../assets/images/details-1.jpeg";
import room2 from "../assets/images/details-2.jpeg";
import room3 from "../assets/images/details-3.jpeg";
import room4 from "../assets/images/details-4.jpeg";
import murree from "../assets/images/murree/murree.jpeg"
import bhurban from "../assets/images/bhurban/bhurban.jpeg"
import bhurban_one from "../assets/images/bhurban/one.jpeg"
import bhurban_two from "../assets/images/bhurban/two.jpeg"
import kalaam from "../assets/images/kalaam/kalaam.jpeg"
import kalaam_one from "../assets/images/kalaam/one.jpeg"
import kalaam_two from "../assets/images/kalaam/two.jpeg"
import kalaam_three from "../assets/images/kalaam/three.jpeg"
import ratti_gali_lake from "../assets/images/ratti_gali_lake/ratt_gali_lake.jpeg"
import ratti_gali_lake_one from "../assets/images/ratti_gali_lake/one.jpeg"
import ratti_gali_lake_two from "../assets/images/ratti_gali_lake/two.jpeg"
import ratti_gali_lake_three from "../assets/images/ratti_gali_lake/three.jpeg"
import mushkpuri_top from "../assets/images/mushkpuri_top/mushkpuri_top.jpeg"
import mushkpuri_top_one from "../assets/images/mushkpuri_top/one.jpeg"
import mushkpuri_top_two from "../assets/images/mushkpuri_top/two.jpeg"
import lake_saiful_muluk from "../assets/images/lake_saiful_muluk/lake_saiful_muluk.jpeg"
import lake_saiful_muluk_one from "../assets/images/lake_saiful_muluk/one.jpeg"
import lake_saiful_muluk_two from "../assets/images/lake_saiful_muluk/two.jpeg"
import lake_saiful_muluk_three from "../assets/images/lake_saiful_muluk/three.jpeg"
import shandur from "../assets/images/shandur/shandur.jpeg"
import shandur_one from "../assets/images/shandur/one.jpeg"
import shandur_two from "../assets/images/shandur/two.jpeg"
import lahore from "../assets/images/lahore/lahore.jpeg"
import lahore_one from "../assets/images/lahore/one.jpeg"
import lahore_two from "../assets/images/lahore/two.jpeg"
import lahore_three from "../assets/images/lahore/three.jpeg"
import islamabad from "../assets/images/islamabad/islamabad.jpeg"
import islamabad_one from "../assets/images/islamabad/one.jpeg"
import islamabad_two from "../assets/images/islamabad/two.jpeg"
import islamabad_three from "../assets/images/islamabad/three.jpeg"
import taxila from "../assets/images/taxila/taxila.jpeg"
import taxila_one from "../assets/images/taxila/one.jpeg"
import taxila_two from "../assets/images/taxila/two.jpeg"
import taxila_three from "../assets/images/taxila/three.jpeg"
import k2 from "../assets/images/k2/k2.jpeg"
import k2_one from "../assets/images/k2/one.jpeg"
import k2_two from "../assets/images/k2/two.jpeg"
import k2_three from "../assets/images/k2/three.jpeg"

import skardu from "../assets/images/skardu/skardu.jpeg"
import gilgit from "../assets/images/gilgit/gilgit.jpeg"
import gilgit_one from "../assets/images/gilgit/one.jpeg"
import gilgit_two from "../assets/images/gilgit/two.jpeg"
import gilgit_three from "../assets/images/gilgit/three.jpeg"
import hunza from "../assets/images/hunza/hunza.jpeg"
import hunza_one from "../assets/images/hunza/one.jpeg"
import hunza_two from "../assets/images/hunza/two.jpeg"
import hunza_three from "../assets/images/hunza/three.jpeg"
import chitral from "../assets/images/chitral/chitral.jpeg"
import chitral_one from "../assets/images/chitral/one.jpeg"
import chitral_two from "../assets/images/chitral/two.jpeg"
import karachi from "../assets/images/karachi/karachi.jpeg"
import karachi_one from "../assets/images/karachi/one.jpeg"
import karachi_two from "../assets/images/karachi/two.jpeg"
import karachi_three from "../assets/images/karachi/three.jpeg"
import khewra from "../assets/images/khewra/khewra.jpeg"
import khewra_one from "../assets/images/khewra/one.jpeg"
import khewra_two from "../assets/images/khewra/two.jpeg"
import khewra_three from "../assets/images/khewra/three.jpeg"
import neelum_valey from "../assets/images/neelum_valley/neelum_valley.jpeg"
import neelum_valey_one from "../assets/images/neelum_valley/one.jpeg"
import neelum_valey_two from "../assets/images/neelum_valley/two.jpeg"
import neelum_valey_three from "../assets/images/neelum_valley/three.jpeg"
import shogran from "../assets/images/shogran/shogran.jpeg"
import shogran_one from "../assets/images/shogran/one.jpeg"
import shogran_two from "../assets/images/shogran/two.jpeg"
import murree_one from "../assets/images/murree/one.jpeg";
import murree_two from "../assets/images/murree/two.jpeg";
import murree_three from "../assets/images/murree/three.jpeg";
import skardu_one from "../assets/images/skardu/one.jpeg";
import skardu_two from "../assets/images/skardu/two.jpeg";
import skardu_three from "../assets/images/skardu/three.jpeg";


export default [
  {
    sys: {
      id: "1"
    },
    fields: {
      name: "murree",
      slug: "murree",
      type: "northern",
      price: 600,
      size: 1000,
      capacity: 10,
      pets: true,
      breakfast: true,
      featured: false,
      description:
        "Murree is a mountain resort town located in the Pir Panjal Range. It is the most visited tourist destination in Pakistan and is surrounded by the most beautiful and scenic Galiats. It forms the outskirts of the Islamabad-Rawalpindi metropolitan area, and is about 30 km northeast of Islamabad. It has an average altitude of 2,291 metres.",
      extras: [
      ],
      images: [
        {
          fields: {
            file: {
              url: murree
            }
          }
        },
        {
          fields: {
            file: {
              url: murree_one
            }
          }
        },
        {
          fields: {
            file: {
              url: murree_two
            }
          }
        },
        {
          fields: {
            file: {
              url: murree_three
            }
          }
        }
      ]
    }
  },
  {
    sys: {
      id: "2"
    },
    fields: {
      name: "bhurban",
      slug: "bhurban",
      type: "northern",
      price: 600,
      size: 1000,
      capacity: 10,
      pets: true,
      breakfast: true,
      featured: false,
      description:
        "Bhurban is a small town and hill station. The resort town is named after a nearby forest. It is located approximately 9 kilometres from Murree city with natural beauty all around. The PC Hotel in Bhurban is the most occupied hotel in the area offering great facilities and indoor activities all year round.",
      extras: [
        "Plush pillows and breathable bed linens",
        "Soft, oversized bath towels",
        "Full-sized, pH-balanced toiletries",
        "Complimentary refreshments",
        "Adequate safety/security",
        "Internet",
        "Comfortable beds"
      ],
      images: [
        {
          fields: {
            file: {
              url: bhurban
            }
          }
        },
        {
          fields: {
            file: {
              url: bhurban
            }
          }
        },
        {
          fields: {
            file: {
              url: bhurban_one
            }
          }
        },
        {
          fields: {
            file: {
              url: bhurban_two
            }
          }
        }
      ]
    }
  },
  {
    sys: {
      id: "3"
    },
    fields: {
      name: "swat",
      slug: "swat",
      type: "northern",
      price: 600,
      size: 1000,
      capacity: 10,
      pets: true,
      breakfast: true,
      featured: true,
      description:
        "Swat is a district in the Malakand Division of the Khyber Pakhtunkhwa province. It is renowned for its outstanding natural beauty. "+
        "Centred upon the upper portions of the Swat River, Swat was a major centre of early Buddhist practices, thought as part of the Gandhara kingdom. "+
        "Swat's average elevation is 980 m (3,220 ft), resulting in a considerably cooler and wetter climate compared to most of Pakistan. With lush forests, verdant alpine meadows, and snow-capped mountains, Swat is one of the country's most popular tourist destinations.",
      extras: [
      ],
      images: [
        {
          fields: {
            file: {
              url: kalaam
            }
          }
        },
        {
          fields: {
            file: {
              url: kalaam_one
            }
          }
        },
        {
          fields: {
            file: {
              url: kalaam_two
            }
          }
        },
        {
          fields: {
            file: {
              url: kalaam_three
            }
          }
        }
      ]
    }
  },
  {
    sys: {
      id: "4"
    },
    fields: {
      name: "ratti gali lake",
      slug: "ratti-gali-lake",
      type: "northern",
      price: 600,
      size: 1000,
      capacity: 10,
      pets: true,
      breakfast: true,
      featured: true,
      description:
        "Taking a position in the Top 10 Lakes of Pakistan, Ratti Gali Lake is truly a blue gem of Azad Kashmir and is one of the main tourist spots in the Neelum Valley. It is an alpine glacial lake that is fed by the gigantic glacial melt of Karakoram Mountain Range. ",
      extras: [
      ],
      images: [
        {
          fields: {
            file: {
              url: ratti_gali_lake
            }
          }
        },
        {
          fields: {
            file: {
              url: ratti_gali_lake_one
            }
          }
        },
        {
          fields: {
            file: {
              url: ratti_gali_lake_two
            }
          }
        },
        {
          fields: {
            file: {
              url: ratti_gali_lake_three
            }
          }
        }
      ]
    }
  },
  {
    sys: {
      id: "5"
    },
    fields: {
      name: "mushkpuri top",
      slug: "mushkpuri_top",
      type: "northern",
      price: 600,
      size: 1000,
      capacity: 10,
      pets: true,
      breakfast: true,
      featured: true,
      description:
        "The 2nd highest hill of Galyat, Mushkpuri Top is located in the wonderful Hills of Nathiagali-District Abbottabad, JKhyber Pakhtunkhwa. "+
        "It is approximately 9452 feet (2,800 meters) above sea level. Mushkpuri is an easy 3 hour trek with magnificent sceneries all the way to the top. "+ 
        "The total climbing distance is 4 kilometers (2.5 miles). The splendid spiraling trek through lush green forests starts from Nathiagali. During winter, the combination of snowflakes, pine trees and grassy land found along this trek is an astounding sight. "+
        "It is a perfect place to breathe in some fresh air and enjoy natural sceneries. The sound of birds in deep silence casts a spell capturing one’s soul to feel the power of nature.",
      extras: [
      ],
      images: [
        {
          fields: {
            file: {
              url: mushkpuri_top
            }
          }
        },
        {
          fields: {
            file: {
              url: mushkpuri_top
            }
          }
        },
        {
          fields: {
            file: {
              url: mushkpuri_top_one
            }
          }
        },
        {
          fields: {
            file: {
              url: mushkpuri_top_two
            }
          }
        }
      ]
    }
  },
  {
    sys: {
      id: "6"
    },
    fields: {
      name: "lake saiful muluk",
      slug: "lake_saiful_muluk",
      type: "northern",
      price: 600,
      size: 1000,
      capacity: 10,
      pets: true,
      breakfast: true,
      featured: true,
      description:
        "The lake Saiful Muluk is located amidst the high mountain ranges of the Karakorum. Fed by the fresh glaciers of Malka Parbat, the lake rests in the Kaghan valley. It is a beautiful, clear water lake with a slight green shade. It is one of the most beautiful lakes of the world and the high, glacier covered mountain tops with lush green slopes surrounding a green-blue patch of water has earned Saiful Muluk its reputation.",
      extras: [
      ],
      images: [
        {
          fields: {
            file: {
              url: lake_saiful_muluk
            }
          }
        },
        {
          fields: {
            file: {
              url: lake_saiful_muluk_one 
            }
          }
        },
        {
          fields: {
            file: {
              url: lake_saiful_muluk_two
            }
          }
        },
        {
          fields: {
            file: {
              url: lake_saiful_muluk_three
            }
          }
        }
      ]
    }
  },
  {
    sys: {
      id: "7"
    },
    fields: {
      name: "shandur",
      slug: "shandur",
      type: "northern",
      price: 600,
      size: 1000,
      capacity: 10,
      pets: true,
      breakfast: true,
      featured: true,
      description:
        "Shandur is located in the Chitral District of Khyber Pakhtunkhwa that connects Chitral District with Ghizer District of Gilgit-Baltistan. It is often called the 'Roof of the World'. The top is a flat plateau, and can be crossed between late April and early November. It is also famous for its annual polo tournament.",
      extras: [
      ],
      images: [
        {
          fields: {
            file: {
              url: shandur
            }
          }
        },
        {
          fields: {
            file: {
              url: shandur
            }
          }
        },
        {
          fields: {
            file: {
              url: shandur_one
            }
          }
        },
        {
          fields: {
            file: {
              url: shandur_two
            }
          }
        }
      ]
    }
  },
  {
    sys: {
      id: "8"
    },
    fields: {
      name: "lahore",
      slug: "lahore",
      type: "city tour",
      price: 600,
      size: 1000,
      capacity: 10,
      pets: true,
      breakfast: true,
      featured: true,
      description:
        "Packed with historic landmarks, bustling eateries, and manicured parks, the vibrant city of Lahore exudes culture at every corner. From soaring minarets and colorful facades to street-level stalls selling flavorful Punjabi favorites, the increasingly cosmopolitan city radiates with joyful energy.",
      extras: [
      ],
      images: [
        {
          fields: {
            file: {
              url: lahore
            }
          }
        },
        {
          fields: {
            file: {
              url: lahore_one
            }
          }
        },
        {
          fields: {
            file: {
              url: lahore_two
            }
          }
        },
        {
          fields: {
            file: {
              url: lahore_three
            }
          }
        }
      ]
    }
  },
  {
    sys: {
      id: "9"
    },
    fields: {
      name: "islamabad",
      slug: "islamabad",
      type: "city tour",
      price: 600,
      size: 1000,
      capacity: 10,
      pets: true,
      breakfast: true,
      featured: false,
      description:
        "Serving as the capital of Pakistan since the Sixties, Islamabad was built according to a carefully organized plan, divided into sectors along a grid of clean, tree-lined streets. The city is sheltered by the Margalla Hills, the foothills of the Himalayas and the home of rare species of leopards, deers, birds, and even porcupines. Several hiking paths end at Daman-e-Koh, a picnic spot with a splendid view of the entire city, including the massive modernist Faisal Mosque and the RawalDam. Islamabad is known for its cultural and heritage events which mostly take place from November to April. ",
      extras: [
      ],
      images: [
        {
          fields: {
            file: {
              url: islamabad
            }
          }
        },
        {
          fields: {
            file: {
              url: islamabad_one
            }
          }
        },
        {
          fields: {
            file: {
              url: islamabad_two
            }
          }
        },
        {
          fields: {
            file: {
              url: islamabad_three
            }
          }
        }
      ]
    }
  },
  {
    sys: {
      id: "10"
    },
    fields: {
      name: "taxila",
      slug: "taxila",
      type: "historical",
      price: 600,
      size: 1000,
      capacity: 10,
      pets: true,
      breakfast: true,
      featured: false,
      description:
        "Taxila is one of the most important archaeological sites in Pakistan and the world. 18 of its sites are on the UNESCO World Heritage List. The city dates back to the Ancient Gandhāran city of Takshashila (also Takkasila or Taxila) an important Vedic/Hindu and Buddhist center of learning from the 6th century BC to the 5th century CE. This is the region from where Buddhism travelled to the far east. Persians, Greeks under Alexander the Great, Central Asians, and Hindus all subsequently left their mark. You can watch the sunset from the remains of a Buddhist monastery or wander through the streets of an excavated Persian city in the knowledge that there are two older ones buried below.",
      extras: [
      ],
      images: [
        {
          fields: {
            file: {
              url: taxila
            }
          }
        },
        {
          fields: {
            file: {
              url: taxila_one
            }
          }
        },
        {
          fields: {
            file: {
              url: taxila_two
            }
          }
        },
        {
          fields: {
            file: {
              url: taxila_three
            }
          }
        }
      ]
    }
  },
  {
    sys: {
      id: "11"
    },
    fields: {
      name: "k2 basecamp",
      slug: "k2-basecamp",
      type: "northern",
      price: 600,
      size: 1000,
      capacity: 10,
      pets: true,
      breakfast: true,
      featured: false,
      description:
       "K2 base camp trek is in the Karakoram mountain range of Gilgit Baltistan. K2 is the second highest mountain in the world and walking to the base camp of this mighty mountain is considered to be one of the most exciting trekking journeys in the world. Nowhere else on earth can you stand so close to many of the world’s highest peaks including K2 (8,611 m), Broad Peak (8,047 m), Gasherbrum (8,080 m), and many more.",
      extras: [
      ],
      images: [
        {
          fields: {
            file: {
              url: k2
            }
          }
        },
        {
          fields: {
            file: {
              url: k2_one
            }
          }
        },
        {
          fields: {
            file: {
              url: k2_two
            }
          }
        },
        {
          fields: {
            file: {
              url: k2_three
            }
          }
        }
      ]
    }
  },
  {
    sys: {
      id: "12"
    },
    fields: {
      name: "skardu",
      slug: "skardu",
      type: "northern",
      price: 600,
      size: 1000,
      capacity: 10,
      pets: true,
      breakfast: true,
      featured: false,
      description:
       "Skardu is a city in the Gilgit-Baltistan region of Pakistan and serves as the capital of the District. The city is an important gateway to the nearby Karakoram Mountain range. The town is located at a 2500-meter height on the Indus river, which separates the Karakoram Range from the Himalayas. It is also known as a “Climbers’ Paradise”.",
      extras: [
      ],
      images: [
        {
          fields: {
            file: {
              url: skardu
            }
          }
        },
        {
          fields: {
            file: {
              url: skardu_one
            }
          }
        },
        {
          fields: {
            file: {
              url: skardu_two
            }
          }
        },
        {
          fields: {
            file: {
              url: skardu_three
            }
          }
        }
      ]
    }
  },
  {
    sys: {
      id: "13"
    },
    fields: {
      name: "gilgit",
      slug: "gitlgit",
      type: "northern",
      price: 600,
      size: 1000,
      capacity: 10,
      pets: true,
      breakfast: true,
      featured: false,
      description:
       "Gilgit-Baltistan is home to five of the 8,000 metre peaks (26,000 ft), and to more than fifty peaks above 7,000 metres (23,000 ft). Gilgit and Skardu are the two main hubs for expeditions to those mountains. The region is home to some of the world's highest mountain ranges, such as the Karakoram and the western Himalayas. Sights of natural beauty are not to be missed in this scenic area.",
      extras: [
      ],
      images: [
        {
          fields: {
            file: {
              url: gilgit
            }
          }
        },
        {
          fields: {
            file: {
              url: gilgit_one
            }
          }
        },
        {
          fields: {
            file: {
              url: gilgit_two
            }
          }
        },
        {
          fields: {
            file: {
              url: gilgit_three
            }
          }
        }
      ]
    }
  },
  {
    sys: {
      id: "14"
    },
    fields: {
      name: "hunza",
      slug: "hunza",
      type: "northern",
      price: 600,
      size: 1000,
      capacity: 10,
      pets: true,
      breakfast: true,
      featured: false,
      description:
       "Majestic Hunza Valley is one of the most beautiful valleys of Pakistan offering wonderful cultural and traditional sights, fantastic hospitality, beautiful views of the mountains including Karakoram ranges, Nanga Parbat, Rakaposhi, Ladyfinger etc., and of course some picturesque lakes.",
      extras: [
      ],
      images: [
        {
          fields: {
            file: {
              url: hunza
            }
          }
        },
        {
          fields: {
            file: {
              url: hunza_one
            }
          }
        },
        {
          fields: {
            file: {
              url: hunza_two
            }
          }
        },
        {
          fields: {
            file: {
              url: hunza_three
            }
          }
        }
      ]
    }
  },
  {
    sys: {
      id: "15"
    },
    fields: {
      name: "chitral",
      slug: "chitral",
      type: "northern",
      price: 600,
      size: 1000,
      capacity: 10,
      pets: true,
      breakfast: true,
      featured: false,
      description:
       "The Chitral District is the largest district in the Khyber-Pakhtunkhwa province of Pakistan, and a part of the Malakand Division. It is the northern most district of Pakistan. It shares its border with beautiful areas like Gilgit-Baltistan to the east, Kunar, Badakshan and Nuristan provinces of Afghanistan to the north and west, and with the Khyber-Pakhtunkhwa districts of Swat and Dir to the south. A narrow strip of Wakhan Corridor separates Chitral from Tajikistan in the north. Located at an elevation of about 4,900 feet (1,490 metres) above sea level, Chitral has a government woolen and sericulture center, the fort of the former chieftain, a polo ground, and fruit gardens in the neighborhood. It is accessible via several mountain passes.",
      extras: [
      ],
      images: [
        {
          fields: {
            file: {
              url: chitral
            }
          }
        },
        {
          fields: {
            file: {
              url: chitral
            }
          }
        },
        {
          fields: {
            file: {
              url: chitral_one
            }
          }
        },
        {
          fields: {
            file: {
              url: chitral_two
            }
          }
        }
      ]
    }
  },
  {
    sys: {
      id: "16"
    },
    fields: {
      name: "karachi",
      slug: "karachi",
      type: "city tour",
      price: 600,
      size: 1000,
      capacity: 10,
      pets: true,
      breakfast: true,
      featured: false,
      description:
       "Karachi is the capital of the Sindh province. It is the most populous city in Pakistan, and fifth-most-populous city in the world. Ranked as a beta-global city, the city is Pakistan's premier industrial and financial center. It is the cultural, economic, philanthropic, educational, and political hub of the country, and Pakistan's most cosmopolitan city. Situated on the Arabian Sea, Karachi serves as a transport hub, and is home to Pakistan's two largest seaports, the Port of Karachi and Port Bin Qasim. It is also very famous for its delicious food, beautiful beaches, social and cultural activities, shopping bazaars and stores, and historical sites.",
      extras: [
      ],
      images: [
        {
          fields: {
            file: {
              url: karachi
            }
          }
        },
        {
          fields: {
            file: {
              url: karachi_one
            }
          }
        },
        {
          fields: {
            file: {
              url: karachi_two
            }
          }
        },
        {
          fields: {
            file: {
              url: karachi_three
            }
          }
        }
      ]
    }
  },
  {
    sys: {
      id: "17"
    },
    fields: {
      name: "khewra salt mines",
      slug: "khewra-salt-mines",
      type: "natural",
      price: 600,
      size: 1000,
      capacity: 10,
      pets: true,
      breakfast: true,
      featured: false,
      description:
       "The Khewra Salt Mine (or Mayo Salt Mine) is located in Khewra, north of Pind Dadan Khan, an administrative subdivision of Jhelum District found in the Punjab region of Pakistan. The mine is located in the Salt Range, an outer range of the Himalaya Mountains which rises from the Indo-Gangetic Plain. It is Pakistan's largest and the world's 2nd largest mine. The mine is famous for its production of pink Himalayan salt, and is a major tourist attraction, drawing up to 250,000 visitors a year. Its history dates back to its discovery by Alexander's troops in 320 BC, but its trading activities began during the Mughal era. The main tunnel at ground level was developed by Dr. H. Warth, a mining engineer, in 1872 during British rule. After independence, the Pakistan Mineral Development Corporation took over the mine, which still remains to be the largest source of salt in the country, producing more than 350,000 tons per annum. Estimates of the reserves of salt in the mine vary from 82 million tons to 600 million tons.",
      extras: [
      ],
      images: [
        {
          fields: {
            file: {
              url: khewra
            }
          }
        },
        {
          fields: {
            file: {
              url: khewra_one
            }
          }
        },
        {
          fields: {
            file: {
              url: khewra_two
            }
          }
        },
        {
          fields: {
            file: {
              url: khewra_three
            }
          }
        }
      ]
    }
  },
  {
    sys: {
      id: "18"
    },
    fields: {
      name: "neelum valley",
      slug: "neelum-valley",
      type: "northern",
      price: 600,
      size: 1000,
      capacity: 10,
      pets: true,
      breakfast: true,
      featured: false,
      description:
       "Neelum Valley is also known as the blue gem of Pakistan. It is one of the most visited destinations within the northern region of the country. Neelum is the district of Azad Kashmir situated almost 90km away from its Capital Muzaffarabad. It is almost 180km away from Islamabad. It is full of natural beauty, waterfalls, green hills, mountains covered with snow, and tall scenic trees.",
      extras: [
      ],
      images: [
        {
          fields: {
            file: {
              url: neelum_valey
            }
          }
        },
        {
          fields: {
            file: {
              url: neelum_valey_one
            }
          }
        },
        {
          fields: {
            file: {
              url: neelum_valey_two
            }
          }
        },
        {
          fields: {
            file: {
              url: neelum_valey_three
            }
          }
        }
      ]
    }
  },
  {
    sys: {
      id: "19"
    },
    fields: {
      name: "shogran",
      slug: "shogran",
      type: "northern",
      price: 600,
      size: 1000,
      capacity: 10,
      pets: true,
      breakfast: true,
      featured: false,
      description:
        "The Beautiful Shogran village is situated on a green plateau of Kaghan Valley in northern Pakistan at a height of 7,749 feet (2,362 meters) above sea level. The beauty of Shogran is further elevated with surrounding peaks and forests. The flowing Kunhar River at the east adds a calming effect to this location.",
      extras: [
      ],
      images: [
        {
          fields: {
            file: {
              url: shogran
            }
          }
        },
        {
          fields: {
            file: {
              url: shogran
            }
          }
        },
        {
          fields: {
            file: {
              url: shogran_one
            }
          }
        },
        {
          fields: {
            file: {
              url: shogran_two
            }
          }
        }
      ]
    }
  }
  
];
