import React from 'react';
import Cover from '../components/Cover';
import Banner from '../components/Banner';
import { Link } from 'react-router-dom';
import Contact from '../components/Contact';

const Contacts: React.FC = () => {
    return (
        <React.Fragment>
        <Cover coverClass="roomsHero">
            <Banner 
            title="contact us">
            <Link to="/" className="btn-primary">return home</Link>
            </Banner>
        </Cover>
        <Contact/>
        </React.Fragment>
    )
}

export default Contacts;