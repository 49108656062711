import React from 'react';
import '../assets/styles/sass/index.scss';
import { Route, Switch } from 'react-router-dom';

import Navbar from '../components/Navbar';
import Home from '../pages/Home';
import UnderConstruction from '../pages/UnderConstruction';
import Rooms from '../pages/Rooms';
import ErrorPage from '../pages/ErrorPage';
import SingleRoom from '../pages/SingleRoom';
import BookNow from '../pages/BookNow';
import Contact from '../pages/Contact';
import About from '../pages/About';
import Footer from '../components/Footer';

const App: React.FC = () => {
  const isMaintenence= false;
  return (
    <React.Fragment>
      
      {!isMaintenence && <Navbar/>
        }
      <Switch>
         {isMaintenence && 
          <Route exact path="/" component={UnderConstruction} />
        }
       { !isMaintenence && 
          <Route exact path="/" component={Home} />
        }
        <Route exact path="/contact/" component={Contact} />
        <Route exact path="/destinations/" component={Rooms} />
        <Route exact path="/destinations/:slug" component={SingleRoom} />
        <Route exact path="/book-now" component={BookNow} />
        <Route exact path="/about" component={About} />
        <Route component={ErrorPage} />
      </Switch>
      <Footer/>
    </React.Fragment>
  );
}

export default App;
