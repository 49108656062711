import React from 'react';
import Cover from '../components/Cover';
import Banner from '../components/Banner';
import { Link } from 'react-router-dom';
import Services from '../components/Services';
import Contact from '../components/Contact';
import FeaturedDestinations from '../components/FeaturedDestinations';

const Home: React.FC = () => {
    return (
        <React.Fragment>
            <Cover>
                <Banner 
                title="holiday destinations"
                subtitle="Special offers available for summer!"
                >
                <Link to="/book-now" className="btn-primary">Book Now</Link>
                </Banner>
            </Cover>
            <Services/>
            <FeaturedDestinations/>
            <Contact/>
        </React.Fragment>
    )
}

export default Home
