import React, { useState, useCallback, useEffect } from 'react';
import Title from './Title';
import { useForm, Controller } from "react-hook-form";
import emailjs from 'emailjs-com';
import DatePicker from "react-datepicker";
 
import "react-datepicker/dist/react-datepicker.css";

function _onFucus(e:any){
  e.currentTarget.type = "date";
}

function _onBlur(e:any){
  e.currentTarget.type = "text";
  e.currentTarget.placeholder = "";
}

export default function App() {
  const [date, setDate] = useState(null);
  const minDate = new Date();
  useEffect(() => {
    setDate(date)
  });
  const { register, handleSubmit, watch, errors } = useForm();
  const onSubmit = useCallback((data: any, e: any) => {
    e.target.reset(); // reset after form submit
    var user_id = "user_XaCAG6chXcwzvxU6FHoV6"
    var service_id = "default_service";
    var template_id = "book_now";
    var startDate = e.target.elements['start'].value;
    emailjs.init(user_id);
    var template_params = {
      "reply_to": data.email,
      "from_name": data.name,
      "phone": data.phone,
      "package": data.package,
      "adult_count": data.adults,
      "children_count": data.children,
      "start_date": startDate ,
      "no_of_days": data.days,
      "message_html": data.message
   }
   
    emailjs.send(service_id , template_id , template_params)
    .then(function(response) {
       console.log('SUCCESS!', response.status, response.text);
    }, function(error) {
       console.log('FAILED...', error);
    });
  }, []);

  console.log(watch("example")); // you can watch individual input by pass the name of the input

  return (
    <section className="contact"> 
    <Title title="inquire"/>
    <div className="local-contact-form">
    <form className="my-form"  onSubmit={handleSubmit(onSubmit)}>
  <div className="container">
    <ul>
      <li>
        <div className="grid grid-2">
          <input type="text" placeholder="Name" required name="name" ref={register} /> {/* register an input */}
          {errors.name && 'name is required.'}
          <input type="text" placeholder="Email" required
          name="email"
          ref={register({
            required: 'E-mail required',
            pattern: {
              value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
              message: "Enter a valid email address"
            }
          })}
        />
        {errors.email && errors.email.message}  
        </div>
      </li>
      <li>
        <div className="grid grid-2">
          <input type="number" placeholder="Phone" required name="phone" ref={register} /> {/* register an input */}
          {errors.name && 'phone is required.'}
          <select id="package" required name="package" ref={register}>
            <option value="">select package</option>
            <option value="budget">budget</option>
            <option value="standard">standard</option>
            <option value="premium">premium</option>
         </select>
        {errors.package && 'select package'}  
        </div>
      </li>  
      <li>
        <div className="grid grid-2">
        <input type="number" placeholder="no. of adults" required name="adults" ref={register} /> {/* register an input */}
          {errors.name && 'adult count is required.'}
          <input type="number" placeholder="no. of children" required name="children" ref={register} /> {/* register an input */}
          {errors.name && 'children count is required.'}
          
        </div>
      </li>
      <li>
        <div className="grid grid-2">
        <input type="number" placeholder="no. of days" required name="days" ref={register} /> {/* register an input */}
          {errors.name && 'duration in days is required.'}
          <DatePicker
      placeholderText="start date"
      name="start"
      ref={register}
      selected={date}
      minDate ={minDate}
      onChange={(date:any) => setDate(date)}
    
    />
          
          
        </div>
      </li>

      <li>
        <textarea placeholder="Enter destinations" required name="message"
        ref={register({ required: true })}></textarea>
      </li>   
      <li>
        <div className="grid grid-3">
          <button className="btn-grid" type="submit">
            <span className="back">
              <img src="https://s3-us-west-2.amazonaws.com/s.cdpn.io/162656/email-icon.svg" alt=""/>
            </span>
            <span className="front">SUBMIT</span>
          </button>
          <button className="btn-grid" type="reset">
            <span className="back">
              <img src="https://s3-us-west-2.amazonaws.com/s.cdpn.io/162656/eraser-icon.svg" alt=""/>
            </span>
            <span className="front">RESET</span>
          </button> 
        </div>
      </li>    
    </ul>
  </div>
</form>
</div>
    </section>
  );
}


