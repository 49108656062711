import React, { useCallback } from 'react';
import Title from './Title';
import { useForm, Controller } from "react-hook-form";
import emailjs from 'emailjs-com';

export default function App() {
  
  const { register, handleSubmit, watch, errors } = useForm();
  const onSubmit = useCallback((data: any, e: any) => {
    e.target.reset(); // reset after form submit
    var user_id = "user_XaCAG6chXcwzvxU6FHoV6"
    var service_id = "default_service";
    var template_id = "template_PPqTjjhz";
    emailjs.init(user_id);
    var template_params = {
      "reply_to": data.email,
      "from_name": data.name,
      "subject": data.subject,
      "message_html": data.message
   }
   
    emailjs.send(service_id , template_id , template_params)
    .then(function(response) {
       console.log('SUCCESS!', response.status, response.text);
    }, function(error) {
       console.log('FAILED...', error);
    });
  }, []);

  console.log(watch("example")); // you can watch individual input by pass the name of the input

  return (
    <section className="contact"> 
    <Title title="get in touch"/>
    <div className="local-contact-form">
    <form className="my-form"  onSubmit={handleSubmit(onSubmit)}>
  <div className="container">
    <ul>
      <li>
        <div className="grid grid-2">
          <input type="text" placeholder="Name" required name="name" ref={register} /> {/* register an input */}
          {errors.name && 'name is required.'}
          <input type="text" placeholder="Email" required
          name="email"
          ref={register({
            required: 'E-mail required',
            pattern: {
              value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
              message: "Enter a valid email address"
            }
          })}
        />
        {errors.email && errors.email.message}  
        </div>
      </li>  
      <li>
        <textarea placeholder="Message" name="message"
        ref={register({ required: true })}></textarea>
      </li>   
      <li>
        <div className="grid grid-3">
          <button className="btn-grid" type="submit">
            <span className="back">
              <img src="https://s3-us-west-2.amazonaws.com/s.cdpn.io/162656/email-icon.svg" alt=""/>
            </span>
            <span className="front">SUBMIT</span>
          </button>
          <button className="btn-grid" type="reset">
            <span className="back">
              <img src="https://s3-us-west-2.amazonaws.com/s.cdpn.io/162656/eraser-icon.svg" alt=""/>
            </span>
            <span className="front">RESET</span>
          </button> 
        </div>
      </li>    
    </ul>
  </div>
</form>
</div>
    </section>
  );
}


