import React from 'react';
import Cover from '../components/Cover';
import Banner from '../components/Banner';
import { Link } from 'react-router-dom';
import Title from '../components/Title';

const About: React.FC = () => {
    return (
        <React.Fragment>
        <Cover coverClass="roomsHero">
            <Banner 
            title="about us"
            >
            <Link to="/" className="btn-primary">return home</Link>
            </Banner>
        </Cover>
        <br/>
        <Title title="team"/>
        <div className="about-info">
        <p>We are a company with a passion for bringing Pakistan on top of the world touring map. The company is headed by a seasoned professional who is an MBA from IBA and has experience of working at senior positions at various international companies inside and outside of Pakistan. Being keen travelers ourselves, we would like to introduce or reintroduce the true beauty of Pakistan to local and international travelers. Sincerity, honesty, and integrity are our core values, with a prime focus on providing tourists with the best services that ensure that their journey is memorable. Our company is registered and licensed by Security Exchange Commission of Pakistan (SECP).Our services will not end once your trip is over as we would continuously like to improve our offerings with your feedback and suggestions.</p>
        </div>
        <br/>
        </React.Fragment>
    )
}

export default About;