import React from 'react';
import Cover from '../components/Cover';
import Banner from '../components/Banner';
import { Link } from 'react-router-dom';
import Booking from '../components/Booking';

const BookNow: React.FC = () => {
    return (
        <React.Fragment>
        <Cover coverClass="roomsHero">
            <Banner 
            title="Book Now"
            >
            <Link to="/destinations" className="btn-primary">return home</Link>
            </Banner>
        </Cover>
        <Booking/>
        </React.Fragment>
    )
}

export default BookNow;