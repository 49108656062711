import React, { Component } from 'react';

import Logo from '../assets/images/logo.png';
import { FaAlignRight, FaPhoneAlt } from 'react-icons/fa';
import { Link } from 'react-router-dom';

interface IStateNavbar {
    isOpen: boolean;
}

export default class Navbar extends Component<{}, IStateNavbar> {

    public readonly state: Readonly<IStateNavbar> = {
        isOpen: false,
    }

    public render() {
        const { isOpen } = this.state;
        return (
            <nav className="navbar">
                <div className="nav-details">
                <button  className="nav-book-now" type="button" ><FaPhoneAlt className="phone-icon"/>+92-333-3094168</button>
                </div>
                <div className="nav-center">
                    <div className="nav-header">
                        <Link to="/">
                            <img className="nav-logo"src={Logo} alt="Pack4pak" />
                        </Link>
                        <button type="button" className="nav-btn" onClick={this.handleToggle}>
                            <FaAlignRight className="nav-icon" />
                        </button>
                    </div>
                    <ul className={isOpen ? "nav-links show-nav" : "nav-links"}>
                        <li><Link to="/">Home</Link></li>
                        <li><Link to="/about">About</Link></li>
                        <li><Link to="/destinations">Destinations</Link></li>
                        <li><Link to="/contact">Contact</Link></li>
                        
                    </ul>
                </div>
                
                
        
            </nav>
        )
    }

    private handleToggle = () => {
        this.setState({ isOpen: !this.state.isOpen });
    }
}
